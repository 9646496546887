import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
// import Image from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faClock, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { StaticImage } from "gatsby-plugin-image"

import infoContact from "./../data/infoContact.json"


const Container = styled.section`
    padding:6rem 0;
    max-width:1300px;
    width:95%;
    margin:0 auto;
    @media(max-width:768px){
        max-width:720px;
    }
    @media(max-width:992px){
        max-width:960px;
    }
    @media(max-width:1200px){
        max-width:1140px;
    }
`

const Info = styled.div`
    text-align:center;
    @media(min-width:1200px){
        text-align:left;
    }
    p:first-of-type{
        font-size: 2.5rem;
        font-weight:bold;
        margin:0;
        padding-bottom:1.5rem;
    }
    p:nth-of-type(2), p:nth-of-type(3){
        color:#5578A8;
        margin:0;
    }
    padding-bottom:5rem;
`

const ALink = styled.a`
    color: #5578A8;
    text-decoration: none;
    font-size:2.3rem;
    @media(min-width:1200px){
        font-size:1.8rem;
    }
    :hover{
        color: #164688;
        text-decoration: underline;
        transition: color .15s ease-in-out;
    }
`

const ALinkMedia = styled.a`
    color: #5578A8;
    text-decoration: none;
    :hover{
        color: #FFBD86;
        transition: color .15s ease-in-out;
    }
`

const ContactUs = () => {

    const [mapaActive, setMapaActive] = useState(false)

    // const { image, allStrapiHomePage } = useStaticQuery(graphql`
    // {
    //     image: file(relativePath: { eq:"patient.png" } ) {
    //         sharp:childImageSharp {
    //           fixed {
    //             ...GatsbyImageSharpFixed
    //           }
    //         }
    //       },
    //       allStrapiHomePage {
    //         nodes {
    //             HorarioDeAtencionDias
    //             HorarioDeAtencionHora
    //             correo
    //             instagram
    //             facebook
    //             telefono
    //         }
    //   }
    // }
    // `)

    // const { HorarioDeAtencionDias, HorarioDeAtencionHora, telefono, correo, instagram, facebook } = allStrapiHomePage.nodes[0]

    return (
        <Container>
            <div>
                <h2
                    css={css`
                    text-align:center;
                    font-size:4rem;
                    text-transform:uppercase;
                    color:#0024B6;
                    padding-bottom:5rem;
                    @media(min-width:768px){
                        font-size:5rem;
                    }
                    @media(min-width:1200px){
                        font-size:4.5rem;
                        text-align:right;
                    }
                `}>
                    Encuéntranos aquí
                </h2>
                <div
                    css={css`
                    @media (min-width:1200px){
                        display:grid;
                        align-items:start;
                        grid-template-columns: 1fr 1.5fr;
                        column-gap:3rem;
                    }
                `}>
                    <div>
                        <Info>
                            <p>Horarios de Atención</p>
                            <p><FontAwesomeIcon css={css`margin-right:1rem; color:#FFBD86;`} icon={faClock} size="1x" />
                                {infoContact.attentionDays}
                            </p>
                            <p>
                                {infoContact.attentionHours}
                            </p>
                        </Info>
                        <Info>
                            <p>Contáctanos</p>
                            <p><FontAwesomeIcon css={css`margin-right:1rem; color:#FFBD86;`} icon={faPhone} size="1x" /><ALink href={"tel:" + infoContact.whatsapp} rel="noopener noreferrer" target="_blank">{infoContact.whatsapp}</ALink></p>
                            <p><FontAwesomeIcon css={css`margin-right:1rem; color:#FFBD86;`} icon={faEnvelope} size="1x" /><ALink href={`mailto:${infoContact.email}?subject=Solicitud de información`} rel="noopener noreferrer" target="_blank">{infoContact.email}</ALink></p>
                        </Info>
                        <Info>
                            <p>Síguenos en</p>
                            <ALinkMedia href={infoContact.facebook} rel="noopener noreferrer" target="_blank" css={css`padding-right:2rem;`}><FontAwesomeIcon icon={faFacebookF} size="2x" /></ALinkMedia>
                            <ALinkMedia href={infoContact.instagram} rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} size="2x" /></ALinkMedia>
                        </Info>
                    </div>
                    <div>
                        <p
                            css={css`
                            text-align:center;
                            font-size: 2.5rem;
                            font-weight:bold;
                            margin:0;
                            padding-bottom:1.5rem;
                            @media(min-width:1200px){
                                text-align:left;
                            }
                        `}>
                            Dirección
                        </p>
                        <div
                            css={css`
                                /* background-color:#F3F4F5; */
                                /* width:100%;
                                height:600px; */
                            `}
                        >
                            {!mapaActive ?
                                <div onClick={() => setMapaActive(true)} >
                                    <StaticImage css={css`cursor:pointer; width:100%; height:100%;`} src="../images/mapa.jpg" alt="Rayodent mapa" />
                                </div>
                                :
                                <iframe title="Location" css={css`border:0;`} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.3026699103284!2d-72.61430764880616!3d-38.73381617949605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9614d3f6806552b9%3A0x83bc359dbc43e194!2zRXNwYcOxYSA0NDYsIFRlbXVjbywgQXJhdWNhbsOtYSwgQ2hpbGU!5e0!3m2!1sen!2sus!4v1617341066164!5m2!1sen!2sus" width="100%" height="600" allowfullscreen="" loading="lazy"></iframe>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ContactUs;