import * as React from "react"
import Layout from './../components/layout'
// import { css } from '@emotion/react'
// import styled from '@emotion/styled'

//Components
import AgendAppointment from './../components/agendAppointment'
import ContactUs from './../components/contactUs'
import Services from './../components/services'
import ShareExperience from './../components/shareExperience'

const IndexPage = () => {

  return (
    <Layout page="Inicio">
      <div id="agenda-una-cita">
        <AgendAppointment />
      </div>
      <div id="servicios">
        <Services />
      </div>
      <div id="encuentranos">
        <ContactUs />
      </div>
      <div id="comentarios">
        <ShareExperience />
      </div>
    </Layout>
  )
}

export default IndexPage
