import * as React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Container = styled.section`
    padding:6rem 0;
    max-width:1300px;
    width:95%;
    margin:0 auto;
    @media(max-width:768px){
        max-width:720px;
    }
    @media(max-width:992px){
        max-width:960px;
    }
    @media(max-width:1200px){
        max-width:1140px;
    }
`

const ButtonCallToAction = styled.a`
border-radius:100px;
border:0;
background-color:#0024B6;
color:#FFF;
font-weight:bold;
transition: padding .6s ease-in-out!important;
font-size:3rem;
padding:1.5rem 3rem!important; 
:hover{
    padding: 1.5rem 4rem!important;
    transition: padding .6s ease-in-out!important;
    color:#FFF;
}
@media(min-width:768px){
    font-size:2rem;
    padding:1.5rem 2rem!important;
    :hover{
    padding: 1.5rem 4rem!important;
    transition: padding .6s ease-in-out!important;
    }
}

`

const Services = () => {

    // const { allStrapiHomePage } = useStaticQuery(graphql`
    // {
    //       allStrapiHomePage {
    //         nodes {
    //             servicios {
    //                 servicios
    //             }
    //         }
    //   }
    // }
    // `);

    // const { servicios } = allStrapiHomePage.nodes[0].servicios;

    let servicios = [
        "Radiología 2D",
        "Radiografías Periapicales",
        "Radiografías Panorámicas",
        "Radiografías Cefalometricas",
        "Radiografía Bitewing",
        "Radiología 3D",
        "Estudio de Implantes",
        "Estudio Endodoncia",
        "Estudio Piezas Incluidas"
    ]

    return (
        <Container>
            <h2
                css={css`
                    text-align:center;
                    font-size:4rem;
                    text-transform:uppercase;
                    color:#0024B6;
                    padding-bottom:5rem;
                    @media(min-width:768px){
                        font-size:5rem;
                    }
                    @media(min-width:1200px){
                        font-size:4.5rem;
                        text-align:left;
                    }
                `}>
                Nuestros Servicios
            </h2>
            <div css={css`
                display: grid;
                align-items:center;
                grid-template-columns: repeat(1, 1fr);
                grid-gap:2rem;
                grid-auto-rows: 150px;
                @media (min-width:768px){
                    grid-template-columns: repeat(2, 1fr);
                }
                @media (min-width:1200px){
                    grid-template-columns: repeat(3, 1fr);
                }
                `}>
                {servicios.map((servicio, i) => {
                    return (
                        <div
                            key={i}
                            css={css`
                            height:100%;
                            background-color: #F3F4F5;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align:center;
                            padding:2rem;
                            transition: all .6s ease-in-out!important;
                            :hover{
                                -webkit-transform:scale(1.15);
                                -moz-transform:scale(1.15);
                                -ms-transform:scale(1.15);
                                -o-transform:scale(1.15);
                                transform:scale(1.15);
                                transition: all .6s ease-in-out!important ;
                                background-color: #0024B6;
                                color: #FFFFFF;
                            }
                        `}>
                            <p>{servicio}</p></div>
                    );
                })}
            </div>
            <div css={css`text-align:center;padding-top:5rem;`}>
                <p>
                    <ButtonCallToAction href="https://6a0300f1f6a00e61667bfcec4c36bae70f52afe0.agenda.softwaredentalink.com/agendas/agendamiento" target="_blank" title="Reserva" css={css`text-decoration:none;`}>Reserva Online</ButtonCallToAction>
                </p>
            </div>
        </Container>
    )
}

export default Services;